// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  SHOW_DEBUG: false,
  API_URL: 'https://areatest.campa.it/api',
  BROWSER_URL: 'https://areatest.campa.it',
  GROUP: 'CAMPA',
  PATHLOGO: './assets/images/logo.png',
  PATHLOGOTB: './assets/images/logo-top-bar.png',
  PATHLOGOJPG: './assets/images/logo.jpg',
  CUSTOMERPHONE: '051.230 967 - 051.269 967',
  MAX_FILE_SIZE: 10485760, // in bynary bytes
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
